<template>
	<div class="mtipotransporteFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipotransporte.idtiptrans')" :value="getIdtiptransValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipotransporte.codtiptransedi')" :value="getCodtiptransediValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipotransporte.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mtipotransporte-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mtipotransporte'
		};
	},
	computed: {
		getIdtiptransValue() {
			return this.model && this.model.idtiptrans ? this.model.idtiptrans : '-';
		},
		getCodtiptransediValue() {
			return this.model && this.model.codtiptransedi ? this.model.codtiptransedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
